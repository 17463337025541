export const DEFAULT_ENTITY = 'product';

export const createParameters = (context, { parameters }) => {
  const { config } = context.$algolia;
  const currentFacets = parameters?.facets || [];

  return {
    ...parameters,
    facets: currentFacets.concat(config.attributesForFilters || []),
    facetingAfterDistinct: typeof parameters.facetingAfterDistinct === 'boolean' ? parameters.facetingAfterDistinct : true
  }
}
