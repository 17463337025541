import { AgnosticHit, NormalizedResponse } from '@vsf-enterprise/algolia-types'
import { AgnosticPrice, AgnosticPagination, AgnosticCategoryTree, AgnosticSort, AgnosticBreadcrumb, AgnosticMediaGalleryItem } from '@vue-storefront/core';
interface UseSearchGetters<RESULT, ITEM> {
  getItems: (result: RESULT) => ITEM[];
  getCategoryTree: (result: RESULT) => AgnosticCategoryTree;
  getPagination: (result: RESULT) => AgnosticPagination;
  getItemPrice: (item: ITEM) => AgnosticPrice;
  getSortOptions: (result: RESULT) => AgnosticSort;
  getBreadcrumbs: (result: RESULT) => AgnosticBreadcrumb[];
  getItemImages: (item: ITEM) => AgnosticMediaGalleryItem[];
  getFilters: (result: RESULT) => any[];
  getItemName: (item: ITEM) => string;
  getItemId: (item: ITEM) => string;
  getItemSlug: (item: ITEM) => string;
}

const searchGetters: UseSearchGetters<NormalizedResponse, AgnosticHit> = {
  getItems: (result: NormalizedResponse) => {
    return result.hits
  },
  getCategoryTree: (result: NormalizedResponse) => {
    return result._categoryTree;
  },
  getPagination: (result: NormalizedResponse) => {
    return result._pagination;
  },
  getSortOptions: (result: NormalizedResponse) => {
    return result._sortOptions;
  },
  getBreadcrumbs: (result: NormalizedResponse) => {
    return result._breadcrumbs; 
  },
  getFilters: (result: NormalizedResponse) => {
    return result._filters || []
  },
  getItemImages: (item: AgnosticHit) => {
    return item._images;
  },
  getItemPrice: (item: AgnosticHit) => {
    return item._price
  },
  getItemName: (item: AgnosticHit) => {
    return item._name
  },
  getItemId: (item: AgnosticHit) => {
    return item._id
  },
  getItemSlug: (item: AgnosticHit) => {
    return item._slug
  }
}

export { searchGetters }