import { useSearchFactory } from '@vue-storefront/core';
import { createParameters, DEFAULT_ENTITY } from '../utils';

const factoryParams = {
  search: async (context, params) => {
    if (!params) {
      return;
    }
    
    const parameters = createParameters(context, params);
    const { 
      facetName,
      facetQuery,
      entity,
      sort,
      useRaw,
      query,
      queries,
      locale
    } = params;

    if (facetName && facetQuery) {
      return context.$algolia.api.searchForFacetValues(
        facetName, 
        facetQuery, 
        parameters, 
        entity || DEFAULT_ENTITY,
        locale,
        useRaw
      );
    }

    if (queries) {
      return context.$algolia.api.multipleQueries(queries, parameters);
    }

    return context.$algolia.api.searchIndex(
      query,
      parameters,
      entity || DEFAULT_ENTITY,
      locale,
      sort,
      useRaw
    );
  }
}

export default useSearchFactory(factoryParams);
